@use "../../styles/vars.scss";
@use "sass:color";

#contact {
  min-height: fit-content;

  .title {
    align-self: center;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 4rem;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__socials {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.2rem;
      width: 18rem;

      &__social {

        display: flex;
        position: relative;
        border-radius: 5rem;

        &:hover {
          .content__socials__social__link {
            width: 100%;
            padding: 0.4rem 0.6rem;

            transition: all 400ms ease-out;
            &__icon {

            }

            &__username {

            }
          }
        }

        &:active {
          .content__socials__social__link {
            transform: translate(-50%, 0) scale(1.05);
            transition: all 600ms, padding 600ms ease-in, transform 200ms;
          }
        }
        

        &__link {
          left: 50%;
          transform: translate(-50%, 0);

          
          z-index: 1;
          
          display: flex;
          column-gap: 0.45rem;
          align-items: center;
          
          width: 32px;
          overflow: hidden;
          
          cursor: pointer;
          
          padding: 0.4rem;
          
          position: relative;
          
          
          outline: none;
          box-sizing: content-box;
          background-color: #FFF;
          border: 0.15rem solid #999;
          border-radius: 5rem;
  
          transition: all 600ms, padding 600ms ease-in;
  
          &__icon {
            font-size: 2rem;
            min-width: 32px;
            max-width: 32px;
  
            &--github {
              color: #24292F;
            }
  
            &--linkedin {
              color: #0A66C2;
            }
  
            &--email {
              color: #666;
            }
          }
  
          &__username {
            display: flex;
            align-items: center;
  
            font-size: 1.1rem;
            letter-spacing: 0.05rem;
            white-space: nowrap;
            height: 100%;
            color: #444;
          }
        }

        &__message {
          position: absolute;
          left: 50%;
          top: 0;
          transform: translate(-50%, -100%);
          
          &__content {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -100%);

            display: flex;
            flex-direction: column;
            align-items: center;

            overflow: hidden;
            height: 0rem;
            width: 0rem;


            transition: all 150ms ease;
            
            &__text {
              color: #FFF;
              white-space: nowrap;
              border-radius: 5rem;
              background-color: #999;

              display: flex;
              justify-content: center;
              align-items: center;

              width: 5rem;
              height: 2rem;
  
            }
          
            &__triangle {
              border-top: 0.25rem solid transparent;
              border-right: 0.5rem solid #999;
              border-bottom: 0.25rem solid transparent;
  
              transform: rotate(-90deg);
            }
          }

          &--show {
            .content__socials__social__message__content {
              animation: 
                jump 200ms ease-in 0s 1 alternate forwards,
                fall 400ms cubic-bezier(.5,.01,.5,.01) 0s 1 alternate forwards,
                jump2 200ms ease-in 400ms 1 alternate forwards,
                fall2 200ms cubic-bezier(.5,.01,.5,.01) 400ms 1 alternate forwards;
              
              height: 2.5rem;
              width: 5.5rem;
            }
          }
    
          @keyframes jump {
            0% {
              transform: translate(-50%, -100%);
            }
            100% {
              transform: translate(-50%, calc(-100% - 2rem));
            }
          }

          @keyframes fall {
            0% {
              transform: translate(-50%, initial);
            }
            100% {
              transform: translate(-50%, calc(-100% + 0.2rem));
            }
          }

          @keyframes jump2 {
            0% {
              transform: translate(-50%, initial);
            }
            100% {
              transform: translate(-50%, calc(-100% - 0.8rem));
            }
          }

          @keyframes fall2 {
            0% {
              transform: translate(-50%, initial);
            }
            100% {
              transform: translate(-50%, -100%);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 775px) {
  #contact {
    .content {
      &__socials {
        &__social {
          &__link {
            width: 100%;
            padding: 0.4rem 0.6rem;
  
            transition: all 400ms ease-out;
          }
        }
      }
    }
  }
}