@use "../../styles/vars.scss";
@use "sass:color";

.home {
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;

  .section {
    display: flex;
    flex-direction: column;
    padding: 5rem 0;
    box-sizing: border-box;
    // Hauteur de la fenêtre - hauteur du header réduit + hauteur de la séparation entre les sections
    min-height: calc(100vh - 3.5rem + 0.2rem);
    background-color: #FFF;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 0.2rem;
      background-color: #444;
      min-width: 20rem;
      width: 80%;
      max-width: 40rem;
      transform: translateX(-50%);
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &__scrollTopButton {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;


    background-color: #444;
    border: 0.15rem solid #333;
    outline: none;
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 0.6rem;
    font-size: 2rem;

    box-sizing: content-box;
    z-index: 1;

    transition: right 400ms;


    animation: showScrollToTopButton 1s forwards;

    cursor: pointer;

    &__hidden {
      animation: hideScrollToTopButton 1s forwards;
    }

    @keyframes hideScrollToTopButton {
      0% {
        opacity: 1;
        transform: translateY(0);
      }
      100% {
        opacity: 0;
        transform: translateY(5rem);
      }
    }

    @keyframes showScrollToTopButton {
      0% {
        opacity: 0;
        transform: translateY(5rem);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    

    &:hover {
      .home__scrollTopButton__icon {
        transform: scale(1.2);
        transition: transform 200ms;
      }
    }

    &:active {
      .home__scrollTopButton__icon {
        transform: scale(1.4);
        transition: transform 200ms;
      }
    }

    &__icon {
      color: #FFF;
      transition: transform 400ms;
    }
  }
}

@media screen and (max-width: 775px) {
  .home {
    &__scrollTopButton {
      right: 1rem;
    }
  }
}