@use "../../styles/vars.scss";
@use "sass:color";

#resume {
  .title {
    align-self: center;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 4rem;
  }

  .content {
    display: flex;
    flex-direction: column;

    &__resume {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 2rem;
      
      &__content {
        display: flex;
        cursor: pointer;
        user-select: none;
        padding: 0 2rem;
        
        &__img {
          width: 100%;
          max-width: 25rem;
        }
      }

      &__actions {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.5rem;

        &__link {
          background-color: #444;
          border: none;
          color: #FFF;
          padding: 0.6rem 1.6rem;
          border-radius: 0.6rem;
          cursor: pointer;
          text-align: center;
        }
      }
      
    }
  }
  
}